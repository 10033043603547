import React from "react";
import "./index.css";
//import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function padNumber(num) {
  // 将数字转换为字符串，并用 0 补齐至长度为 3
  return String(num).padStart(3, "0");
}

const BookCard = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    // S3
    var s3key = padNumber(props.Num) + "_" + props.ID;
    Cookies.set("refTitle", props.Title);
    // 哈哈。 这里先检查登录态，没登陆不能看
    if (Cookies.get("usertoken") === undefined) {
      Cookies.set("refID", s3key);
      navigate("/signin");
      return;
    }
    navigate("/viewpdf/" + s3key);
  };

  return (
    <div className="BookContainer">
      <div className="BookPhoto">
        <img
          src={props.PhotoURL}
          alt="PhotoURL"
          className="BookImage"
          onClick={() => handleClick(props.File)}
        />
        <img
          src="../books/bs.png"
          alt="Best"
          className="BestImage"
          style={{ width: "40px", height: "40px" }}
        />
      </div>
      <div className="BookIntro">
        <div className="BookTitle" onClick={() => handleClick(props.File)}>
          {props.Title}
        </div>
        <div className="BookAuthor">{props.Author}</div>
        <div className="BookScore">{props.Score}</div>
        <div className="BookDesc">{props.Desc}</div>
        <div className="BookWords">
          Read in <span>{props.ReadTime}</span> Min! ({props.Origin} to{" "}
          <b>{props.Compress} </b> Words)
        </div>
      </div>
      <div className="SepLine"></div>
    </div>
  );
};
export default BookCard;
