import "./App.css";
//import MyBook from "./components/Test/MyBook";
//import MyFlex from "./components/Test/MyFlex";
import Navbar from "../../components/Navbar";
import IntroSection from "../../components/IntroSection";
import Gallery from "../../components/Gallery";
import TabComponent from "../../components/Tab";
import ReactGA from "react-ga";
import { useEffect } from "react";

function sendEmail() {
  window.location.href = "mailto:support@insightreader.ai";
}

function App() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    //console.log("App Mounted");
  }, []);

  // 这里目前是 3 个元素，分别是标题、内容、底部 Tab
  return (
    <div className="App">
      <Navbar />
      <div className="ContentPanel">
        <Gallery />
      </div>
      <div className="TabComponent">
        <TabComponent />
      </div>
      <div className="Feedback" onClick={sendEmail}>
        <img className="FeedbackImage" src="feedback.png" alt="feedback" />
      </div>
      <IntroSection />
    </div>
  );
}

export default App;
