import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import IntroSection from "../../components/IntroSection";
import LoadingIndicator from "../../components/LoadingIndicator";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import "./index.css";

const PageFile = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const navigate = useNavigate();
  const { pdfS3Key } = useParams();
  var [pdfURL, setPDFURL] = useState("");
  var pdfTitle = Cookies.get("refTitle");

  useEffect(() => {
    var finalS3Key = "/BooksV2Gen/" + pdfS3Key + ".pdf";
    fetch("/api/geturl", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fileKey: finalS3Key }),
    })
      .then((response) => response.json())
      .then((data) => {
        const encodedUrl = encodeURIComponent(data.data.fileURL);
        setPDFURL(encodedUrl);
      });
  });

  return (
    <div className="CenterList">
      <div className="FileTitle">{pdfTitle}</div>
      {pdfURL === "" ? (
        <div>
          <LoadingIndicator />
        </div>
      ) : (
        <iframe
          src={"/pdfjs/viewer.html?file=" + pdfURL}
          title="PDF"
          className="PDFContainer"
        ></iframe>
      )}
      <Button
        onClick={() => navigate("/")}
        style={{ marginTop: "10px", marginBottom: "10px" }}
        variant="contained"
      >
        Back to Home{" "}
      </Button>
      <IntroSection />
    </div>
  );
};

export default PageFile;
