import BookCard from "../BookCard/index.js";
import LoadingIndicator from "../LoadingIndicator/index.js";
import "./index.css";
import { useState, useEffect } from "react";

// 合集列表
function CollectionList(props) {
  var cachedData = localStorage.getItem("myCollection" + props.ID);
  if (!cachedData) {
  } else {
    cachedData = JSON.parse(cachedData);
  }
  const [data, setData] = useState(cachedData);

  useEffect(() => {
    // 请求网络数据
    fetch("/api/db/collbooks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: props.ID }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData.data);
        localStorage.setItem(
          "myCollection" + props.ID,
          JSON.stringify(jsonData.data)
        );
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  }, [props.ID]);

  return (
    <div className="CollectionContainer">
      {data ? (
        data.map((item) => (
          <div key={item.ID} className="BookItem">
            <BookCard
              ID={item.ID}
              Num={item.Num}
              PhotoURL={item.Photo}
              Title={item.Title}
              Author={item.Author}
              Score={item.Score}
              Desc={item.Desc}
              File={item.File}
              Origin={item.Origin}
              Compress={item.Compress}
              ReadTime={item.ReadTime}
            />
          </div>
        ))
      ) : (
        <div>
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
}

export default CollectionList;
