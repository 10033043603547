import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import Cookies from "js-cookie";

const RecCard = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    Cookies.set("refTitle", props.Title);
    navigate("/viewlist/" + props.ID);
  };

  return (
    <div className="RecContainer" onClick={handleClick}>
      <div className="RecTitle">{props.Title}</div>
      <div className="RecCover">
        <img className="RecPhoto" src={props.PhotoURL} alt="PhotoRec" />
        <div className="RecIntro"> {props.SubTitle} </div>
      </div>
    </div>
  );
};
export default RecCard;
