import React from "react";
import ReactGA from "react-ga";
import CollectionList from "../../components/CollectionList";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import "./index.css";
import IntroSection from "../../components/IntroSection";
import Cookies from "js-cookie";

const PageList = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const navigate = useNavigate();
  const { collectionId } = useParams();
  var collTitle = Cookies.get("refTitle");
  return (
    <div className="CenterList">
      <div className="CollTitle">{collTitle}</div>
      <div className="SepLine"></div>
      <CollectionList ID={collectionId} />
      <div className="BottomArea">
        <Button onClick={() => navigate("/")} variant="contained">
          Back to Home
        </Button>
      </div>
      <IntroSection />
    </div>
  );
};

export default PageList;
