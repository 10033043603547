import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IntroSection from "../../components/IntroSection";
import LoadingIndicator from "../../components/LoadingIndicator";
import Cookies from "js-cookie";
import "./index.css";

const PageJump = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 如果是已经登陆，并且 refID 不为空，那么跳转到阅读页面
    var token = Cookies.get("usertoken");
    var s3ref = Cookies.get("refID");
    if (token !== undefined && s3ref !== undefined) {
      Cookies.remove("refID");
      navigate("/viewpdf/" + s3ref);
    } else {
      navigate("/");
    }
  });

  return (
    <div className="CenterList">
      <div>
        <LoadingIndicator />
      </div>
      <IntroSection />
    </div>
  );
};

export default PageJump;
