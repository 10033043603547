import React, { useEffect } from "react";

const GoogleLogin = (props) => {
  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.onload = () => {
        /*window.google.accounts.id.initialize({
          client_id:
            "887450027524-ke91okdr85kpg48j9j6m5l4c3iopqkec.apps.googleusercontent.com",
          callback: handleCredentialResponse,
          context: "signin",
          ux_mode: "popup",
          login_uri: "https://insightreader.ai/login/google",
          use_fedcm_for_prompt: false,
          prompt_parent_id: "google_btn",
        });
        window.google.accounts.id.prompt(); // Show the One Tap prompt
        window.google.accounts.id.renderButton(
          document.getElementById("google_btn"),
          {
            type: "standard",
            theme: "outline",
            size: "large",
            text: "signin_with",
            shape: "rectangular",
            logo_alignment: "left",
          }
        );*/
      };
      document.body.appendChild(script);
    };

    loadGoogleScript();
  }, []);

  //const handleCredentialResponse = (response) => {
  //console.log("Encoded JWT ID token: " + response.credential);
  // Handle the response, e.g., send it to your backend for verification
  // 这里直接不处理，让后端来 302 跳转
  //};

  return (
    <div>
      <div
        id="g_id_onload"
        data-client_id="887450027524-ke91okdr85kpg48j9j6m5l4c3iopqkec.apps.googleusercontent.com"
        data-context="signin"
        data-ux_mode="popup"
        data-login_uri="https://insightreader.ai/login/google"
        data-auto_prompt="false"
        data-prompt_parent_id="g_id_onload"
        data-ref_id={props.refID}
      ></div>
      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="signin_with"
        data-size="large"
        data-logo_alignment="left"
      ></div>
    </div>
  );
};

export default GoogleLogin;
