import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

const PageSignup = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="CenterList">
        <button onClick={() => navigate(-1)}> Back </button>
        <h1>Page Signup</h1>
      </div>
    </div>
  );
};

export default PageSignup;
