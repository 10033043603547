import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./pages/PageApp/App";
import PageList from "./pages/PageList";
import PageFile from "./pages/PageFile";
import PageLogin from "./pages/PageLogin";
import PageSignup from "./pages/PageSignup";
import GPTLab from "./pages/GPTLab";
import PageMUI from "./pages/PageMUI";
import PageJump from "./pages/PageJump";
import PageProfile from "./pages/PageProfile";
import PageNeedAdmin from "./pages/PageNeedAdmin";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/mui" element={<PageMUI />} />
        <Route path="/signup" element={<PageSignup />} />
        <Route path="/signin" element={<PageLogin />} />
        <Route path="/jump" element={<PageJump />} />
        <Route path="/profile" element={<PageProfile />} />
        <Route path="/gptlab" element={<GPTLab />} />
        <Route path="/needadmin" element={<PageNeedAdmin />} />
        <Route path="/viewlist/:collectionId" element={<PageList />} />
        <Route path="/viewpdf/:pdfS3Key" element={<PageFile />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
