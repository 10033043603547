import React, { useEffect, useState } from "react";
import "./index.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("usertoken");
    Cookies.remove("username");
    Cookies.remove("useravatar");
    setIsLoggedIn(false);
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  function gotoLogin() {
    navigate("/signin");
  }

  function gotoHome() {
    navigate("/");
  }

  useEffect(() => {
    // 检查 cookie 中的登录状态
    const token = Cookies.get("usertoken"); // 假设你的 cookie 名为 'authToken'
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <div className="NavBarPanel">
      <div className="NavBarContainer">
        <div className="NavBarLogo" onClick={gotoHome}>
          <img
            src="banner.png"
            alt="irlogo"
            style={{ height: "30px", marginTop: "20px", marginLeft: "30px" }}
          />
        </div>
        <div className="NavRight">
          <div className="LoginBtn" onClick={gotoLogin}>
            Login
          </div>
          {isLoggedIn ? (
            <div className="LoginUserContainer">
              <div
                className="LoginUserAvatar"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <img
                  src={Cookies.get("useravatar")}
                  alt="head"
                  className="circular-image"
                  title={Cookies.get("username")}
                  onClick={handleClick}
                />
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleProfile}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          ) : (
            <div className="StartBtn" onClick={gotoLogin}>
              Login
            </div>
          )}
        </div>
      </div>
      <div className="sepLine"></div>
    </div>
  );
};

export default Navbar;
