import React from "react";
import "./index.css";

const DiscordLogin = (props) => {
  const gotoDiscord = () => {
    window.open(
      "https://discord.com/oauth2/authorize?client_id=1305212270292504626&response_type=code&redirect_uri=https%3A%2F%2Finsightreader.ai%2Flogin%2Fdiscord&scope=identify+email",
      "_self"
    );
  };

  return (
    <div className="DiscordContainer">
      <img
        src="discord.png"
        alt="discord"
        className="DiscordImage"
        onClick={gotoDiscord}
      />
    </div>
  );
};
export default DiscordLogin;
