import React from "react";
import { Tabs, Tab } from "@mui/material";
import RecCard from "../RecCard";
import BookCard from "../BookCard/index.js";
import "./index.css";
//import { CollectionArray } from "./data.js";
import { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import LoadingIndicator from "../../components/LoadingIndicator";
import LocalStorageWithExpiry from "../../utils/storage.js";

function TabPanel(props) {
  // 自己实现一个 TabPanel 组件，传入属性，children，value，index
  // children 显示在 Box 里面
  // value 是当前选中的 tab 的 index
  // index 是当前 tab 的 index
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function CollectionGrid() {
  const [coll, setColl] = useState(null);

  useEffect(() => {
    var mycoll = storage.getWithExpiry("collection");
    if (mycoll) {
      var jsoncoll = JSON.parse(mycoll);
      setColl(jsoncoll);
    } else {
      setColl(null);
      // 请求网络数据
      fetch("/api/db/collections", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((jsonData) => {
          setColl(jsonData.data);
          storage.setWithExpiry(
            "collection",
            JSON.stringify(jsonData.data),
            600 * 1000 // 10 minutes
          );
        })
        .catch((error) => {
          console.error("Error fetching JSON:", error);
        });
    }
  }, []);

  return (
    <div>
      <div className="RecGridContainer">
        {coll ? (
          coll.map((item) => (
            <div key={item.ID} className="RecCardContainer">
              <RecCard
                ID={item.ID}
                PhotoURL={item.Photo}
                Title={item.Title}
                BookCount={item.BookCount}
                SubTitle={item.SubTitle}
              />
            </div>
          ))
        ) : (
          <div className="LoadingContainer">
            <LoadingIndicator />
          </div>
        )}
      </div>
    </div>
  );
}

const storage = new LocalStorageWithExpiry();

// 书籍列表
function BookList() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  var { totalNum } = useState(298);
  var { pageSize } = useState(10);
  totalNum = 298;
  pageSize = 10;

  useEffect(() => {
    var myBooklist = storage.getWithExpiry("booklist01");
    if (myBooklist) {
      var jsonbook = JSON.parse(myBooklist);
      setData(jsonbook);
    } else {
      setData(null);
      // 请求网络数据
      fetch("/api/db/booklist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pageSize: 10, pageIndex: 1 }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          setData(jsonData.data);
          storage.setWithExpiry(
            "booklist01",
            JSON.stringify(jsonData.data),
            86400 * 1000 // 24 hours
          );
        })
        .catch((error) => {
          console.error("Error fetching JSON:", error);
        });
    }
  }, []);

  useEffect(() => {
    var myBooklist = storage.getWithExpiry("booklist" + padNumber(currentPage));
    //console.log("changePage", myBooklist);
    if (myBooklist) {
      var jsonbook = JSON.parse(myBooklist);
      setData(jsonbook);
    } else {
      setData(null);
      // 请求网络数据
      fetch("/api/db/booklist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pageSize: pageSize, pageIndex: currentPage }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          setData(jsonData.data);
          storage.setWithExpiry(
            "booklist" + padNumber(currentPage),
            JSON.stringify(jsonData.data),
            86400 * 1000 // 24 hours
          );
        })
        .catch((error) => {
          console.error("Error fetching JSON:", error);
        });
    }
  }, [currentPage, pageSize]);

  function padNumber(num) {
    return num.toString().padStart(2, "0");
  }

  function changePage(event, page) {
    setCurrentPage(page);
  }

  return (
    <div>
      <div className="pageContainer">
        {" "}
        <Pagination
          count={Math.ceil(totalNum / 10)}
          shape="rounded"
          onChange={changePage}
          page={currentPage}
          showFirstButton
          showLastButton
        />
      </div>
      <div className="BookContainer">
        {data ? (
          data.map((item) => (
            <div key={item.ID} className="BookItem">
              <BookCard
                ID={item.ID}
                Num={item.Num}
                PhotoURL={item.Photo}
                Title={item.Title}
                Author={item.Author}
                Score={item.Score}
                Desc={item.Desc}
                File={item.File}
                Origin={item.Origin}
                Compress={item.Compress}
                ReadTime={item.ReadTime}
              />
            </div>
          ))
        ) : (
          <div className="LoadingContainer">
            <LoadingIndicator />
          </div>
        )}
      </div>
      <Pagination
        count={Math.ceil(totalNum / 10)}
        shape="rounded"
        onChange={changePage}
        page={currentPage}
        showFirstButton
        showLastButton
      />
    </div>
  );
}

const TabComponent = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Collections" />
        <Tab label="Latest" />
      </Tabs>
      <div className="TabPanel">
        <TabPanel key={0} value={value} index={0}>
          <CollectionGrid />
        </TabPanel>
        <TabPanel key={1} value={value} index={1}>
          <BookList />
        </TabPanel>
      </div>
    </div>
  );
};

export default TabComponent;
