import React from "react";
import IntroSection from "../../components/IntroSection";
import "./index.css";

const PageNeedAdmin = () => {
  return (
    <div className="CenterList">
      <div className="LoginTitle">Insight Reader</div>
      <div className="LoginContainer">
        <h2> Need Admin Permissions </h2>
      </div>
      <IntroSection />
    </div>
  );
};

export default PageNeedAdmin;
