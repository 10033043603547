// src/component/Gallery/index.js
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Gallery.css";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { count: 0 };
    //console.log("Gallery Constructor");
    //this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // 在组件挂载后（首次渲染完成后）被调用。
    //console.log("Gallery Did Mounted");
  }

  componentDidUpdate() {
    // 在组件更新后被立即调用。首次渲染不会执行此方法。
    //console.log("Gallery Did Update");
  }

  shouldComponentUpdate(nextProps, nextState) {
    // 在组件接收到新的 props 或 state 时被调用。在初始化时或者使用 forceUpdate 时不被调用。
    //console.log("Gallery should Update");
    return true;
  }

  componentWillUnmount() {
    // 在组件卸载及销毁之前直接调用。
    //console.log("Gallery Will Unmounted");
  }

  render() {
    return (
      <Carousel
        autoPlay
        interval="6000"
        transitionTime="500"
        infiniteLoop
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <img src="covers/p11.jpg" alt="" />
          <p className="GallreyTitle">
            Quickly absorb key insights from full-length books in under 15
            minutes
          </p>
        </div>
        <div>
          <img src="covers/p22.jpg" alt="" />
          <p className="GallreyTitle2">
            Enjoy condensed versions of books without sacrificing essential
            takeaways
          </p>
        </div>
        <div>
          <img src="covers/p33.jpg" alt="" />
          <p className="GallreyTitle3">
            Read like a pro, gaining knowledge quickly and effectively
          </p>
        </div>
        <div>
          <img src="covers/p44.jpg" alt="" />
          <p className="GallreyTitle4">
            Speak confidently on a wide range of topics after a brief read
          </p>
        </div>
      </Carousel>
    );
  }
}
export default Gallery;
