import React, { useEffect } from "react";
import ReactGA from "react-ga";
import GoogleLogin from "../../components/GoogleLogin";
import DiscordLogin from "../../components/DiscordLogin";
//import FacebookLogin from "../../components/FacebookLogin";
//<FacebookLogin appId="3921944991461437" apiVersion="v21.0" />
import IntroSection from "../../components/IntroSection";
import "./index.css";

const PageLogin = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  useEffect(() => {
    //console.log("PageLogin useEffect");
  });

  return (
    <div className="CenterList">
      <div className="LoginTitle">Log in to Insight Reader</div>
      <div className="LoginContainer">
        <GoogleLogin />
        <DiscordLogin />
      </div>
      <IntroSection />
    </div>
  );
};

export default PageLogin;
