import React, { useEffect } from "react";
import IntroSection from "../../components/IntroSection";
import Cookies from "js-cookie";
import "./index.css";

const PageProfile = () => {
  useEffect(() => {});

  return (
    <div className="CenterList">
      <div className="LoginTitle">Change YOUR Profile</div>
      <div className="LoginContainer">
        <h2> user: {Cookies.get("username")}</h2>
      </div>
      <IntroSection />
    </div>
  );
};

export default PageProfile;
